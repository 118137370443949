/* *{
background-color: #282c34;
} */
.naglowek {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-items: center;
  height: 80px;
  box-sizing: border-box;
  width: 100%;
  
}
h1{
  color: #E91A9D;;
  font-family:Arial, Helvetica, sans-serif;
  font-size: 40px;
}

.right{
  background-color: #282c34;
  display: flex;
  justify-content: center;
  width: 70%;
  float: right;
  height: calc(100vh - 80px);
  
}
.frame{
  height: 70vh;
  width: 120vh;
  border: 5px solid gray;
  padding: 5px;
  margin-top: 45px;
  overflow: none;
  
}
button{
  cursor: pointer;
  font-size: 18px;
  font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  background-color: #00C4FF;
  border-radius: 6px;
  width: 80%;
  height: 50px;
  margin-left: 40px;
  color: white;;
  box-shadow: 0 9px rgb(0, 144, 201);
  margin-top: 45px;
  

}
button:hover{
  background-color: #0096c4;
}
button:active {
  background-color: #00C4FF;
  box-shadow: 0 5px #018cb6;
  transform: translateY(8px);
}
button:focus{
  background-color: #027294;
  box-shadow: 0 5px #00455a;

}
.list{
  overflow: auto;
  background-color: #282c34;
  width: 30%;
  float: left;
  height: calc(100vh - 80px);

}


.logo_blueform{
  margin-left: 80px;
  width: 80%;
  
}
.logo_software{
  display: flex;
  justify-content: flex-start;
}
.logo_fi{
  margin-right: 50px;
}
.footer, .footer-content, .footer-title {
  background-color: rgb(87, 87, 87);
  bottom: 0;
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
  color: white;
}
.footer a{
  color: white;
  text-decoration: none;
}
.footer{
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: fixed;
}
.mask-footer:hover{
    height: 111px;
}
.pulpit-footer{
  bottom: 40px;
  position: absolute;
}
.footer-title{
    text-align: center;
    color: white;
    z-index: 100;
    position: absolute;
    bottom: 0;
    width: 120px !important;
    left: calc(50% - 60px) !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 25px !important; 
    line-height: 25px !important;
}

.footer-content{
    position: fixed;
    bottom: 0;
    z-index: 1;
    transition: 1s;
    overflow: hidden;
    bottom: 0 !important;
    height: 0 !important;
}
.footer h5 {
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
}
body{
  margin: 0;
}
.footer-title:hover + .footer-content, .footer-content:hover{
    height: 140px !important;
} 

.firmotron{
  width: 50%;
  display: flex;
  justify-content: flex-end;
  float: right;
  position: absolute;
  top: 20px;
  right: 25px;
}
.software{
  width: 50%; 
  float: left;
  display: flex;
  flex-direction: column;
}
.logo-image{
  padding: 20px;
}

::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: gray; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray; 
}